<template>
    <div :class="`p-2 ${$isMobile() ? '' : 'container-lg'}`" v-if="shelter">

        <!-- header -->
        <div class="mb-1">
            <div @click="goBack()" class="h4 mb-0 text-white d-flex align-items-center" style="">
                <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                Back
            </div>
        </div>

        <div class="p-1" style="border-radius: 10px;border:1px solid #FFAD32">
            <div class="d-flex">
                <img style="height:150px !important;width:150px !important;object-fit:cover" class="rounded-circle m-auto" 
                :src="shelter.shelter_picture" />
            </div>
        
            <div class="text-white text-center my-1 px-2">
                <div class="d-flex align-items-center justify-content-center" style="gap:10px">
                    <h2 class="text-white font-weight-bolder mb-0">{{ shelter.shelter_name }}</h2>
                    <router-link :to="`/chats/${shelter.user_id}`" v-if="user.user_id != shelter.user_id">
                        <div class="text-white d-flex align-items-center" style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                            <i class="bx bxs-message-dots" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                        </div>
                    </router-link>
                    <router-link :to="`/shelter-register`" v-else>
                        <div class="text-white d-flex align-items-center" style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                            <i class="bx bxs-edit" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                        </div>
                    </router-link>
                </div>
                <p class="mt-1">
                    {{ shelter.shelter_details }}
                </p>

                <h3 class="font-weight-bold text-white">{{shelter.shelter_location}}</h3>
                <h3 class="font-weight-bold text-white mb-0">{{ shelter.shelter_phone }}</h3>
            </div>
            
            <div class="d-flex">
                <div class="badge bg-danger m-auto" v-if="shelter.is_approved == 'n'">
                    Waiting for approval
                </div>
            </div>
        </div>
        <!-- end header -->

        <!-- volunteering -->
        <div class="d-flex justify-content-between align-items-center" style="gap:10px">
            <h3 class="text-white font-weight-bolder text-center mt-3 h3">Available Donation</h3>
            <h3 @click="addNew()" class="text-white font-weight-bolder text-right mt-3 h6"
                v-if="user.user_id == shelter.user_id">Add Event</h3>
        </div>

        <div class="h4 text-center text-white mt-1" v-if="donation.length < 1">
            No Data Available
        </div>

        <div class="d-flex flex-column mt-1" style="gap:20px">
            <div class="p-1" style="border-radius: 10px;border:2px solid #FFAD32" v-for="item in donation">
                <img class="w-100 rounded mb-1" v-if="item.shd_picture" style="object-fit: cover;aspect-ratio: 4/1;"
                    :src="item.shd_picture" />
                
                <div class="d-flex align-items-center justify-content-between" style="gap:10px">
                    <div class="h3 text-white mb-0">{{ item.shd_title }}</div>
                    <i v-if="user.user_id == shelter.user_id" @click="edit(item)" class="bx bxs-edit text-primary ml-auto"
                            :style="`font-size: ${$isMobile() ? '22px' : '32px'};`"></i>
                </div>

                <div class="d-flex align-items-center" style="gap:10px">
                    <div class="my-1 progress progress-bar-warning border flex-fill">
                        <div class="progress-bar " role="progressbar" aria-valuenow="65" aria-valuemin="65"
                            aria-valuemax="100" :style="`width: ${((item.shd_raised ? item.shd_raised : 0) / item.shd_fund_pledge) * 100}%`"></div>
                    </div>
                    <h5 class="mb-0 text-white">{{((item.shd_raised ? item.shd_raised : 0) / item.shd_fund_pledge) * 100}}%</h5>
                </div>

                <div class="row" style="gap:10px 0">
                    <div class="col-6">
                        <div class="text-white h5 mb-0">Pledge: $ {{item.shd_fund_pledge}},00</div>
                    </div>
                    <div class="col-6">
                        <div class="text-white h5 mb-0">Raised: $ {{(item.shd_raised ? item.shd_raised : 0)}},00</div>
                    </div>
                    <div class="col-6">
                        <div class="text-white h5 mb-0">Donators: {{item.donators.length}}</div>
                    </div>
                </div>

                <div class="text-white h5 font-weight-bolder" style="margin-top: 1em;">Details: </div>

                <p class="text-white" style="margin-top: .5em;">
                    {{ item.shd_details }}
                </p>

                <div class="d-flex flex-wrap align-items-center justify-content-center" style="gap:10px" v-if="user.user_id != shelter.user_id">
                    <!-- <div class="btn" style="
                        border-radius: 15px;
                        border: 2px solid #FFAD32;
                        background: #005071;
                        color: white;
                    ">
                        <i class="bx bx-share-alt" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                    </div> -->
                    <div class="btn py-1 px-3" style="
                        border-radius: 15px;
                        border: 2px solid #FFAD32;
                        background: #005071;
                        color: white;
                    " @click="openModal(item)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample">
                        Donate
                    </div>
                    <!-- <div class="btn" style="
                        border-radius: 15px;
                        border: 2px solid #FFAD32;
                        background: #005071;
                        color: white;
                    ">
                        <i class="bx bxs-message-dots" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                    </div> -->
                </div>
                <div class="d-flex flex-wrap align-items-center justify-content-center" style="gap:10px" v-else>
                    <div class="btn py-1 px-3" style="
                        border-radius: 15px;
                        border: 2px solid #FFAD32;
                        background: #EA5455;
                        color: white;
                    " @click="deleteEvent(item)">
                        Delete
                    </div> 
                </div>

            </div>
        </div>
        <!-- end volunteering -->

        <!--Offcanvas Panels-->
        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel"
            style="background-color: #1B628C;border-radius: 10px 10px 0 0;bottom: 80px;height: auto !important;">
            <div :class="`offcanvas-body ${$isMobile() ? '' : 'container-lg'}`">
                <div class="px-2 py-3" v-if="isShown">
                    <img class="w-100 rounded mb-1" v-if="isShown.shd_picture" style="max-height: 100px;object-fit: cover;"
                    :src="isShown.shd_picture" />

                    <div class="h3 text-white mb-0">{{ isShown.shd_title }}</div>

                    <div class="d-flex align-items-center" style="gap:10px">
                        <div class="my-1 progress progress-bar-warning border flex-fill">
                            <div class="progress-bar " role="progressbar" aria-valuenow="65" aria-valuemin="65"
                                aria-valuemax="100" :style="`width: ${((isShown.shd_raised ? isShown.shd_raised : 0) / isShown.shd_fund_pledge) * 100}%`"></div>
                        </div>
                        <h5 class="mb-0 text-white">{{((isShown.shd_raised ? isShown.shd_raised : 0) / isShown.shd_fund_pledge) * 100}}%</h5>
                    </div>

                    <div class="row" style="gap:10px 0">
                        <div class="col-6">
                            <div class="text-white h5 mb-0">Pledge: $ {{isShown.shd_fund_pledge}},00</div>
                        </div>
                        <div class="col-6">
                            <div class="text-white h5 mb-0">Raised: $ {{(isShown.shd_raised ? isShown.shd_raised : 0)}},00</div>
                        </div>
                        <div class="col-6">
                            <div class="text-white h5 mb-0">Donators: 0</div>
                        </div>
                    </div>

                    <div class="text-white h5 font-weight-bolder" style="margin-top: 1em;">Details: </div>

                    <p class="text-white" style="margin-top: .5em;">
                        {{ isShown.shd_details }}
                    </p>

                    <div class="mt-3 h3 text-white text-center font-weight-bolder">How Much Would You Like to Donate?</div>
                    <input type="number" v-model="donating_amount" class="form-control mb-2 text-center" style="background-color: transparent;color: white;
                    border:unset;border-bottom: 1px solid white !important;border-radius: unset;" />

                    <div class="d-flex flex-wrap align-items-center justify-content-center" style="gap:10px">
                        <div class="btn px-3" style="
                        border-radius: 15px;
                        border: 2px solid #FFAD32;
                        background: #C10000;
                        color: white;
                    ">
                            Cancel
                        </div>
                        <div class="btn px-3" style="
                        border-radius: 15px;
                        border: 2px solid #FFAD32;
                        background: #005071;
                        color: white;
                    " @click="donateNow()">
                            Donate
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <h3 class="text-white font-weight-bolder mt-3 h3">Recent Donators</h3>

            <div class="h4 text-center text-white mt-1" v-if="shelter.donators.length < 1">
                No Data Available
            </div>

            <div class="p-1 mt-1" style="border-radius: 10px;border:2px solid #FFAD32" v-for="item in shelter.donators">
                <div class="d-flex align-items-center" style="gap:10px 20px">
                    <div>
                        <img :src="item.user.user_picture ? item.user.user_picture : default_img" class="rounded-circle"
                            style="height:50px;width:50px;border:2px solid white" />
                    </div>
                    <div>
                        <h3 class="text-white h4 mb-0">{{ item.user.user_name }}</h3>
                        <span class="text-white">Donating at {{ moment(item.created_at).format('dddd, DD MMMM YYYY') }}</span>
                    </div>
                </div>
                <div class="d-flex align-items-center mt-1" style="gap:10px 20px">
                    <div class="h4 text-white mb-0">Amount ${{ item.nominal }} on {{ item.donation_event.shd_title }} {{ item.donation_event.shd_cause }}</div>
                </div> 
            </div>
        </div>

    </div>
</template> 

<script>

import store from '@/store'
import moment from 'moment'
import Swal from 'sweetalert2'

import Cookies from 'universal-cookie'

let cookies = new Cookies()

export default {
    computed: {
        user() {
            return store.state.auth.USER
        },
        shelter() {
            return store.state.shelter.SHELTER
        },
        donation() {
            return this.shelter?.donation
        },
    },
    watch: {
        donating_amount(val){ 
            if(val > this.isShown.shd_fund_pledge - (this.isShown.shd_raised ? this.isShown.shd_raised : 0)){
                this.donating_amount = this.isShown.shd_fund_pledge - (this.isShown.shd_raised ? this.isShown.shd_raised : 0)
            }
        }
    },
    methods: {
        // donateNow() {
        //     this.$router.push('/shelter/donate/payment')
        // },
        deleteEvent(id){
            Swal.fire({
                title: 'Are you sure to delete this event?',
                text: 'You can recover the data by contacting the admin',
                showCancelButton: true,
                confirmButtonText: 'Yes',
            }).then((result) => {
                if (result.isConfirmed) {
                    store.dispatch('shelter/DeleteDonation', id.shd_id).then(() => {
                        store.dispatch('shelter/GetShelter', this.id)
                    })
                }
            })
        },
        goBack(){
            this.$router.push('/shelter/profile/'+this.id)
        },
        edit(item) {
            this.$router.push('/shelter/donate/form/' + this.id + '/' + item.shd_id)
        },
        addNew() {
            this.$router.push('/shelter/donate/form/' + this.id)
        },
        openModal(item) {
            this.isShown = item
            this.donating_amount = 0
        },
        donateNow() {
            // store.dispatch('shelter/ApplyDonation', ).then(() => {
                this.$router.push({ path: '/shelter/donate/payment', query: {
                    user_id: cookies.get('token'),
                    shd_id: this.isShown.shd_id,
                    shelter_id: this.id,
                    nominal: this.donating_amount,
                    item: this.isShown
                }})
            // })
        }
    },
    mounted() {
        store.dispatch('shelter/GetShelter', this.id)
        $(document).trigger("enhance");

    },
    data() {
        return {
            moment,
            donating_amount: 0,
            id: this.$route.params.id,
            isShown: null,
            default_img: 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png'
        }
    }
}

</script>